#root {
  margin: auto;
  max-width: 1920px;
}

div {
  display: flex;
}

.swiper-wrapper {
  width: 100vw !important;
}

:disabled {
  cursor: not-allowed;
}
body {
  background-color: #f8f7fa !important;
  font-family: "Poppins", sans-serif !important;
}
.App {
  flex-direction: column;
  width: 100%;
  background-color: #f8f7fa;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.accordion-button:not(.collapsed) {
  background-color: transparent !important;
  border: none !important;
  box-shadow: 0;
}

.accordion-button:not(.collapsed) {
  color: black !important;
}

.App-header {
  background-color: #2f3349;
  min-height: 44px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0.2rem 0rem 0.2rem 1.5rem;
}

.App-link {
  color: #61dafb;
}

.modal-content {
  border-radius: 20px !important;
  top: 3rem;
}

.autocompleteField {
  /* width: 80% !important; */
  width: 100% !important;
  height: 43px;
  border-radius: 4px;
}
@media (max-width: 600px) {
  .autocompleteField {
    width: 100% !important;
    height: 38px;
    border-radius: 4px;
  }
}

.autocompleteField .MuiAutocomplete-inputRoot {
  height: 42.2px;
  border-radius: 4px;
}

.autocompleteField fieldset {
  border-color: #4f4f4f;
}

.autocompleteField input {
  color: #000;
  width: 100%;
  background: #fff !important;
  /* border: 1px solid #00000080 !important; */
  border: 1px solid rgba(201, 199, 206, 1) !important;
  border-radius: 4px !important;
  @media (max-width: 600px) {
    padding: 10px !important;
    background: #fff !important;
    /* border: 1px solid #00000080 !important; */
    border: 1px solid rgba(201, 199, 206, 1) !important;
  }
}

/* ---- */
.autocompleteFieldAfterLogin {
  width: 100% !important;
  height: 43px;
  border-radius: 4px;
}

.autocompleteFieldAfterLogin .MuiAutocomplete-inputRoot {
  height: 42.2px;
  border-radius: 4px;
}

.autocompleteFieldAfterLogin fieldset {
  border-color: #4f4f4f;
}

.autocompleteFieldAfterLogin input {
  color: #000;
  width: 100%;
  background: #fff !important;
  /* border: 1px solid #00000080 !important; */
  border: 1px solid rgba(201, 199, 206, 1) !important;
  border-radius: 4px !important;
  @media (max-width: 600px) {
    padding: 10px !important;
    background: #fff !important;
    /* border: 1px solid #00000080 !important; */
    border: 1px solid rgba(201, 199, 206, 1) !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.spinner-container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.p-focus {
  border: none;
  border-color: #fff !important;
  box-shadow: none !important;
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #1585b5 !important;
  font-weight: bold;
  padding: 0;
}

.p-treeselect-panel {
  flex-direction: column;
}
.p-treeselect-panel .p-treeselect-items-wrapper .p-tree {
  width: 100%;
}

.p-treeselect-items-wrapper {
  flex-direction: column;
}
.p-tree-container {
  width: 100%;
}
.p-treeselect .p-treeselect-label {
  color: #1585b5;
  font-size: 14px;
  font-weight: bold;
}
.p-treeselect .p-treeselect-trigger {
  width: 2rem !important;
}
.p-overlaypanel .p-overlaypanel-content {
  flex-direction: column;
  padding: 0 !important;
}
.p-chip .p-chip-text {
  line-height: 1.2;
  margin-top: 0.375rem;
  font-size: 0.9rem;
  margin-bottom: 0.375rem;
  font-weight: bold;
}
.p-chip .p-chip-remove-icon {
  font-size: 1rem;
  font-weight: 600;
}

.code-block {
  width: 96%;
  height: 150px;
  margin: 14px;
  position: relative;
}
.code-block-iframe {
  width: 96%;
  height: 150px;
  margin: 14px;
  position: relative;
}
.code-block p,
.code-block-iframe p {
  position: absolute;
  color: #fff;
  z-index: 9;
  right: 42px;
  top: 12px;
  letter-spacing: 0px;
  word-spacing: -5px;
}
#basic-navbar-nav {
  background-color: #ffffff00;
}

.questionRequestSummaryTable .p-datatable {
  flex-direction: column;
}

.questionRequestSummaryTable td {
  text-align: center !important;
}

.questionRequestSummaryTable .p-paginator .p-paginator-pages .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
}

.userSubscriptionsHistoryTable .p-datatable {
  flex-direction: column;
  width: 100%;
  margin-top: 1rem;
}

.userSubscriptionsHistoryTable td {
  /* text-align: center !important; */
}

.userSubscriptionsHistoryTable
  .p-paginator
  .p-paginator-pages
  .p-paginator-page {
  min-width: 2rem;
  height: 2rem;
}

.landing-nav a {
  margin-left: 2rem;
}

.slider {
  display: block !important;

  margin-right: 17px;
}
.slider-mobile {
  display: block !important;
}

.rangeslider__fill {
  width: 302px !important;
  background: #1585b5 !important;
}

.rangeslider-horizontal {
  height: 5px !important;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 24px !important;
  height: 24px !important;
}

.rangeslider-horizontal .rangeslider__handle:after {
  width: 15px !important;
  height: 15px !important;
  top: 3.4px !important;
  left: 4px !important;
}

.p-datepicker {
  flex-direction: column !important;
}

.p-datepicker-group {
  flex-direction: column !important;
  flex: 1;
}

.p-inputtext {
  border-radius: 0 !important;
  border: 1px solid #eaeaea !important;
  background-color: #f8f7fa;
}

.p-inputtext:hover,
.p-button-icon-only:hover {
  border: 1px solid #eaeaea !important;
}

.p-button-icon-only {
  background-color: #1585b5 !important;
}

.dataSourceTable .p-datatable-wrapper {
  width: 100vw;
  font-size: 15px;
}

.dataSourceTable th.titleColumn {
  color: #343a40;
}

.dataSourceTable .titleColumn {
  color: #1585b5;
  width: 50%;
  font-weight: bold;
}
.dataSourceTable .titleColumn .sourcename {
  color: #33303c;
  font-weight: normal;
}

.dataSourceTable th {
  text-transform: uppercase;
  font-size: 14px;
}

.markdown {
  white-space: "pre-wrap" !important;
  flex-direction: column !important;
}

.dataSourceTable thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: #343a40;
  background: #f8f9fa;
  transition: box-shadow 0.2s;
}
.dataSourceTable tbody > tr > td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}
.ReactTags__tags.react-tags-wrapper {
  flex-direction: column-reverse;
}

.ReactTags__tags {
  position: relative;
}

.ReactTags__clearAll {
  cursor: pointer;
  padding: 10px;
  margin: 10px;
  background: #f88d8d;
  color: #fff;
  border: none;
}

/* Styles for the input */
.ReactTags__tagInput {
  border-radius: 2px;
  display: inline-block;
}
.ReactTags__tagInput input.ReactTags__tagInputField,
.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 31px;
  margin: 0;
  font-size: 12px;
  border: 1px solid #eee;
  min-width: 150px;
}

.ReactTags__editInput {
  border-radius: 1px;
}

.ReactTags__editTagInput {
  display: inline-flex;
}

/* Styles for selected tags */
.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #ddd;
  background: #1585b5;
  color: white;
  font-size: 12px;
  display: inline-block;
  padding: 5px;
  margin: 0 5px;
  border-radius: 2px;
  margin-bottom: 0.5rem;
}
.ReactTags__selected a.ReactTags__remove {
  color: #aaa;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
.ReactTags__suggestions {
  position: absolute;
}
.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}
.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}
.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}
.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__remove {
  border: none;
  cursor: pointer;
  background: none;
  color: white;
}
.ReactTags__tagInput {
  margin-bottom: 0.5rem;
}
.ReactTags__tagInputField {
  width: 100%;
  padding: 20px;
  background: #eaeaea;
  border: 1px solid #eaeaea !important;
  display: inline;
}
span.tag-wrapper:nth-child(1) {
  margin-left: 0;
}
.reactBottomScroll {
  position: inherit !important;
}
.reactBottomScroll > div::-webkit-scrollbar {
  display: none;
}
.ReactCrop {
  padding: 0 !important;
}
.acc-item {
  margin: 10px 0px;
  display: block;
  border-radius: 2px;
  border: 1px solid rgba(234, 234, 234, 1);
}
.accordion-button {
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
}
.accordion-collapse.collapse.show {
  display: block !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.chatArea > div::-webkit-scrollbar {
  display: none;
}
.sampleQuestionParent ::-webkit-scrollbar {
  display: none;
}
body ::-webkit-scrollbar,
textarea[name="prompt"]::-webkit-scrollbar {
  display: none;
}
.beforeLoginHeaderItem {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: rgba(51, 48, 60, 1) !important;
  font-weight: 600 !important;
}
textarea::-webkit-scrollbar {
  display: block;
}
.zendesk-subdomain-input-field {
  padding-right: 107px !important;
}

.markdown img,
.markdown li img {
  width: 25%;
}

.uf-notification-inner,
.uf-modal-inner {
  display: block;
}

.uf-actions {
  flex-direction: column;
}

@media only screen and (max-width: 800px) {
  .markdown img,
  .markdown li img {
    width: 50%;
  }
}
